import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HttpClientXsrfModule, HttpClientJsonpModule } from "@angular/common/http";
import { ConnectionServiceModule } from 'ng-connection-service';
import { NgCircleProgressModule } from "ng-circle-progress";
import { AppComponent } from "./app.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContentComponent } from './layout/content/content.component';
// import * as jQuery from 'jquery';
// import "animate.css";
// import { CookieService } from "ngx-cookie-service";
import { AuthInterceptorService } from "./services/auth-interceptor.service";
import { NetworkInterceptorService } from "./services/network-interceptor.service";
import { AuthGuard } from "./services/auth.guard";
import { UnauthorizedComponent } from './unauthorized/unauthorized/unauthorized.component';
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from "ngx-toastr";
// import { ServicesModule } from "./services/services.module";
import { EventEmitterService } from "./services/eventemitter.service";
import { ConferenceRoomComponent } from './conference-room/booking/conference-room.component';
import { DashboardComponent } from "./ticket/dashboard/dashboard.component";
import { TicketDashboardInfoComponent } from "./ticket/ticket-dashboard-info/ticket-dashboard-info.component";


@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    ContentComponent,
    UnauthorizedComponent,
    ConferenceRoomComponent,
    DashboardComponent,
    TicketDashboardInfoComponent
  ],
  imports: [

    SharedModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    
    ConnectionServiceModule,
     
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-CSRF-TOKEN'
    }),
    NgCircleProgressModule.forRoot({
      radius: 60,
      outerStrokeWidth: 10,
      innerStrokeWidth: 5,
      showBackground: false,
      startFromZero: false
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    // ServicesModule,

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, 
      useClass: AuthInterceptorService, 
      multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptorService,
      multi: true
    }, {
      provide: AuthInterceptorService, 
      useClass: AuthGuard
    },
    {
      provide:LocationStrategy,
      useClass:HashLocationStrategy
    },  EventEmitterService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
