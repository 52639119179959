
<div class="row">
    <div class="col-sm-3 card-content card-content">
    <p-card class="p-card" (click)="redirectToDestination('')">   
    <h3 class="total-tickets" class="total-tickets">Total Number of Tickets</h3>
    <div class="count-text">
        <i class="fa fa-calculator" aria-hidden="true"></i>
        <ng-container *ngIf="ticket_list">
            <p>{{this.ticket_list.all_total_tickets}}</p>
        </ng-container>
    </div>
    </p-card> 
    </div>

    <div class="col-sm-3 card-content">
    <p-card (click)="redirectToDestination('Admin and Infra')">   
    <h3 class="total-tickets">Ticket for Admin team</h3>
    <div class="count-text">
        <i class="fa fa-users" aria-hidden="true"></i>
        <p>{{ getCountByName('Admin and Infra') }}</p>
    </div>
    </p-card> 
    </div>
    <div class="col-sm-3 card-content">
        <p-card (click)="redirectToDestination('Finance')">   
        <h3 class="total-tickets">Ticket for Finance team</h3>
        <div class="count-text">
            <i class="fa fa-money" aria-hidden="true"></i>
            <p>{{ getCountByName('Finance') }}</p>
        </div>
        </p-card> 
    </div>
    <div class="col-sm-3 card-content">
        <p-card (click)="redirectToDestination('Human Resources')">   
        <h3 class="total-tickets">Ticket for HR team</h3>
        <div class="count-text">
            <i class="fa fa-handshake-o" aria-hidden="true"></i>
            <p>{{ getCountByName('Human Resources') }}</p>
        </div>
        </p-card> 
    </div>
</div>

<div class="row">
    <div class="col-sm-3 card-content">
    <p-card class="p-card" (click)="redirectToDestination('','','today')">   
    <h3 class="total-tickets">Today Ticket Count</h3>
    <div class="count-text">
        <i class="fa fa-ticket" aria-hidden="true"></i>
        <ng-container *ngIf="ticket_list">
        <p>{{this.ticket_list.today_created_total_tickets}}</p>
        </ng-container>
    </div>
    </p-card> 
    </div>

    <div class="col-sm-3 card-content">
    <p-card (click)="redirectToDestination('Admin and Infra','','today')">   
    <h3 class="total-tickets">Ticket for Admin team</h3>
    <div class="count-text">
        <i class="fa fa-users" aria-hidden="true"></i>
        <p>{{ gettodayCountByName('Admin and Infra') }}</p>
    </div>
    </p-card> 
    </div>
    <div class="col-sm-3 card-content">
        <p-card (click)="redirectToDestination('Finance','','today')">   
        <h3 class="total-tickets">Ticket for Finance team</h3>
        <div class="count-text">
            <i class="fa fa-money" aria-hidden="true"></i>
            <p>{{ gettodayCountByName('Finance') }}</p>
        </div>
        </p-card> 
    </div>
    <div class="col-sm-3 card-content">
        <p-card (click)="redirectToDestination('Human Resources','','today')">   
        <h3 class="total-tickets">Ticket for HR team</h3>
        <div class="count-text">
            <i class="fa fa-handshake-o" aria-hidden="true"></i>
            <p>{{ gettodayCountByName('Human Resources') }}</p>
        </div>
        </p-card> 
    </div>
</div>






<div class="row">
    <div class="col-sm-3 card-content">
    <p-card class="p-card" (click)="redirectToDestination('','closed')">   
    <h3 class="total-tickets">Total closed Tickets</h3>
    <div class="count-text">
        <i class="fa fa-window-close-o" aria-hidden="true"></i>
        <ng-container *ngIf="ticket_list">
        <p>{{this.ticket_list.closed_total_tickets}}</p>
        </ng-container>
    </div>
    </p-card> 
    </div>

    <div class="col-sm-3 card-content">
        <p-card (click)="redirectToDestination('Admin and Infra','closed')">   
            <h3 class="total-tickets">Ticket for Admin team</h3>
            <div class="count-text">
                <i class="fa fa-users" aria-hidden="true"></i>
                <p>{{ getclosedCountByName('Admin and Infra') }}</p>
            </div>
            </p-card> 
            </div>
            <div class="col-sm-3 card-content">
                <p-card (click)="redirectToDestination('Finance','closed')">   
                <h3 class="total-tickets">Ticket for Finance team</h3>
                <div class="count-text">
                    <i class="fa fa-money" aria-hidden="true"></i>
                    <p>{{ getclosedCountByName('Finance') }}</p>
                </div>
                </p-card> 
            </div>
            <div class="col-sm-3 card-content">
                <p-card (click)="redirectToDestination('Human Resources','closed')">   
                <h3 class="total-tickets">Ticket for HR team</h3>
                <div class="count-text">
                    <i class="fa fa-handshake-o" aria-hidden="true"></i>
                    <p>{{ getclosedCountByName('Human Resources') }}</p>
                </div>
                </p-card> 
    </div>
</div>


<div class="row">
    <div class="col-sm-3 card-content">
    <p-card class="p-card" (click)="redirectToDestination('','tat')">   
    <h3 class="total-tickets">Total Beyond TAT</h3>
    <div class="count-text">
        <i class="fa fa-clock-o" aria-hidden="true"></i>
        <ng-container *ngIf="ticket_list">
        <p>{{this.ticket_list.tat_total_tickets}}</p>
        </ng-container>
    </div>
    </p-card> 
    </div>

    <div class="col-sm-3 card-content" >
        <p-card (click)="redirectToDestination('Admin and Infra','tat')">   
            <h3 class="total-tickets">Ticket for Admin team</h3>
            <div class="count-text">
                <i class="fa fa-users" aria-hidden="true"></i>
                <p>{{ gettatCountByName('Admin and Infra') }}</p>
            </div>
            </p-card> 
            </div>
            <div class="col-sm-3 card-content">
                <p-card (click)="redirectToDestination('Finance','tat')">   
                <h3 class="total-tickets">Ticket for Finance team</h3>
                <div class="count-text">
                    <i class="fa fa-money" aria-hidden="true"></i>
                    <p>{{ gettatCountByName('Finance') }}</p>
                </div>
                </p-card> 
            </div>
            <div class="col-sm-3 card-content">
                <p-card (click)="redirectToDestination('Human Resources','tat')">   
                <h3 class="total-tickets">Ticket for HR team</h3>
                <div class="count-text">
                    <i class="fa fa-handshake-o" aria-hidden="true"></i>
                    <p>{{ gettatCountByName('Human Resources') }}</p>
                </div>
                </p-card> 
    </div>
</div>