<p-progressSpinner *ngIf="isLoading" styleClass="custom-spinner" strokeWidth="6" fill="#EEEEEE" animationDuration=".5s">
</p-progressSpinner>
<div  class="p-card" *ngIf="!isLoading">
  <div class="p-fluid">
      <div class="p-field">
          <p class="form-error" *ngIf="display_msg">{{response_status}}</p>
           <p class="form-error1" *ngIf="display_msg1">{{response_status}}</p>
          <p class="form-error1" *ngIf="not_valid">Please fill all the fields!</p>
          <form  class="apply_form" [formGroup]="conferenceForm">
          <div class="row">
              <div class="form-group col-sm-6">
                  <label for="date_from">Location</label>
                  <p-dropdown 
                  formControlName="location" [options]="location"
                  name="location"  required [(ngModel)]="location_id"
                       [required]="true" optionLabel="name" optionValue="name"
                       (onChange)="onLocationChange($event)" >
                  </p-dropdown>
                  <div *ngIf="conferenceForm.get('location').touched && conferenceForm.get('location').invalid">
                    <small style="color: red;">Location required</small>
                </div>
              </div>
              <div class="form-group col-sm-6">
                <label for="date_from">Conference Room</label>
                <p-dropdown 
                formControlName="conference_room" [options]="filtered_Conference_Rooms"
                name="conference_room"  required [(ngModel)]="conference_room" 
                    [required]="true" optionLabel="name" optionValue="id" 
                >
                </p-dropdown>
                <div *ngIf="conferenceForm.get('conference_room').touched && conferenceForm.get('conference_room').invalid">
                  <small style="color: red;">Conference Room required</small>
              </div>
              </div>
             
          </div>
          <div class="row">
              <div class="form-group col-sm-4">
                  <label  for="disabledays">Select Date</label>
                  <p-calendar inputId="basic"  required  formControlName="date" name="date" [(ngModel)]="date" readonlyInput="true"
                      placeholder="Date" dateFormat="yy-mm-dd" [disabledDays]="[0,6]"></p-calendar>
                      <div *ngIf="conferenceForm.get('date').touched && conferenceForm.get('date').invalid">
                        <small style="color: red;">Date required</small>
                    </div>
              </div>
              <div class="form-group col-sm-4" >
                <div >
                    <label for="disabledays">Time From</label>
                    <p-calendar formControlName="from_time" hourFormat="12"  name="from_time"  [(ngModel)]="from_time" readonlyInput="true" required  [timeOnly]="true"  placeholder="Time from"
                  timeFormat="H:mm:i" class="timer" ></p-calendar>
                </div>
                <div *ngIf="conferenceForm.get('from_time').touched && conferenceForm.get('from_time').invalid">
                  <small style="color: red;">Time From required</small>
              </div>
            </div> 
               
            <div class="form-group col-sm-4" >
                    <div >
                        <label for="disabledays">Time To</label>
                        <p-calendar formControlName="to_time"  hourFormat="12" name="to_time" [(ngModel)]="to_time" readonlyInput="true"  required  [timeOnly]="true"  placeholder="Time to"
                  timeFormat="HH:mm:i" class="timer"></p-calendar>
                    </div>
                    <div *ngIf="conferenceForm.get('to_time').touched && conferenceForm.get('to_time').invalid">
                      <small style="color: red;">Time To required</small>
                  </div>
            </div> 
             
            </div>
             
          
          <div class="row">
            <div class="form-group col-sm-6">
              <label for="date_from">Members</label>
              <input type="text" formControlName="members"
              name="members"  required [(ngModel)]="members" placeholder="members"  pInputText pattern="[0-9]*" digitOnly/>
              <div *ngIf="conferenceForm.get('members').touched && conferenceForm.get('members').invalid">
                <small style="color: red;">Members required</small>
            </div>
          </div>
            <div class="form-group col-sm-6">
              <label>Reason</label>
              <textarea pInputTextarea  [rows]="1" placeholder="Reason" name="reason"
              formControlName="reason" [(ngModel)]="reason" [maxlength]="500"  (ngModelChange)="reason_length(reason)" required >
              
              </textarea>
              <small style="color:#F5821f; float: right;">Characters Remaining:{{reason_char}}</small>
             
              <div *ngIf="conferenceForm.get('reason').touched && conferenceForm.get('reason').invalid">
                <small style="color: red;">Reason required</small>
            </div>
          </div> 
        
          </div>
         
      </form>
          
           <div class="form-group justify-content-between text-right"> 
              <button pButton pRipple type="button" label="Submit"
                          class="p-button-sm p-button-rounded p-button-success mr-3 btn-submit" (click)="submit()" ></button>
              <button pButton pRipple type="button" label="Clear"
                          class="p-button-sm p-button-rounded p-button-secondary btn-clear" (click)="clear()" ></button>                    
          </div>
        
      </div>
  </div>
</div>

