<nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
  <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
      <a class="navbar-brand brand-logo mr-20" routerLink="/profile/profile">
        <img src="assets/images/optisolLogo.png" alt="logo" /> 
      </a>
      <a class="navbar-brand brand-logo-mini" routerLink="/profile/profile">
        <img src="assets/images/optisolLogo-mini.png" alt="logo" /> 
      </a>
      <button class="navbar-toggler navbar-toggler align-self-center dnone-mobile" type="button" data-toggle="minimize" (click)="toggleIconOnlySidebar()">
        <span class="iconsizes icon-menu"></span>
      </button> 
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-center">
      
    <!-- <button class="navbar-toggler navbar-toggler align-self-center" type="button" >
      <span class="iconsizes icon-size-actual"></span>
    </button>  
    <button class="navbar-toggler navbar-toggler align-self-center" type="button" >
      <span class="iconsizes icon-size-fullscreen"></span>
    </button>  -->
    <ul class="navbar-nav navbar-nav-right">     
      <li class="nav-item dropdown d-none d-xl-inline-flex" ngbDropdown>
        <span class="mb-1i mt-3 font-weight-semibold" style="text-align:center ;">{{user_name}}</span>
        <a class="nav-link dropdown-toggle" id="UserDropdown" ngbDropdownToggle>
          <span *ngIf="imagePath!=''" class="img_setround"><img [src]="'data:image/jpg;base64,'+imagePath" class="img_widset"/></span>
          <span *ngIf="imagePath===''" class="img_setround"><img src="assets/images/avatar.jpg" class="img_widset"/></span>
          <!-- <img [src]="'data:image/jpg;base64,'+imagePath" class="img-md rounded-circle"  /> -->
        </a>
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" ngbDropdownMenu aria-labelledby="UserDropdown">
          <div class="dropdown-header text-center">
            <!-- <img [src]="'data:image/jpg;base64,'+imagePath" class="img-md rounded-circle"/> -->
            <span *ngIf="imagePath!=''" class="img_setround"><img [src]="'data:image/jpg;base64,'+imagePath" class="img_widset"/></span>
            <span *ngIf="imagePath===''" class="img_setround"><img src="assets/images/avatar.jpg" class="img_widset"/></span>
            <p class="mb-1 mt-3 font-weight-semibold">{{user_name}}</p>
            <p class="font-weight-light text-muted mb-0">{{work_mail}}</p>
          </div>
          <!-- <button class="dropdown-item" (click)="router.navigate(['profile/profile'])">My Profile</button> -->
          <!-- <a class="dropdown-item" (click)="router.navigate(['profile/profile'])">My Profile</a> -->
          <a class="dropdown-item" (click)="clickedMenu($event,'profile')"
          [ngClass]="selectedItem === 'profile' ? 'active' : 'none'" id="nav-link1" routerLink="profile/profile"
          routerLinkActive="active">
          <span class="menu-title">My Profile</span>
        </a>
          <button class="dropdown-item" (click)="change_pwd()">Change Password</button>     
          <button class="dropdown-item" (click)="logout()">Sign Out</button>
        </div>
      </li>
    </ul>
    <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center ml-auto pr-0" type="button"
      (click)="toggleRightSidebar()">
      <span class="mdi mdi-menu"></span>
    </button>
  </div>
 
</nav>

<!---CHange Password-->

<p-dialog header="Change Password" [closable]="false" [(visible)]="displayBasic" [modal]="true" [style]="{width: '40vw'}"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" class="changepw_modal">
    <p class="form-error" *ngIf="display_msg">{{status}}</p>
    <div class="text-align:center">
        <div class="p-fluid">
            <div class="p-field">
                <div class="form-group">
                    <input type="password" pInputText placeholder="Current Password" name="current_pwd" [(ngModel)]="current_pwd" required #current="ngModel" />
                    <div *ngIf="current.touched && current.invalid">
                        <small style="color: red;">Current Password required</small>
                    </div>
                </div>
                <div class="form-group">
                    <input type="password" pInputText placeholder="New Password" name="new_pwd" [(ngModel)]="new_pwd"  required #new="ngModel"  />
                    <div *ngIf="new.touched && new.invalid">
                        <small style="color: red;">New Password required</small>
                    </div>
                </div>
                <div class="form-group">
                    <input type="password" pInputText placeholder="ConfirmPassword" name="retype_pwd" [(ngModel)]="retype_pwd"  required #retype="ngModel" />
                    <div *ngIf="retype.touched && retype.invalid">
                      <small style="color: red;">Confirm Password required</small>
                    </div>
                </div>

                <div class="form-group justify-content-between text-right mb-0">
                  <button pButton pRipple type="button" label="Save"
                              class="p-button-sm p-button-rounded p-button-success mr-3 btn-submit" (click)="save()"></button>
                  <button pButton pRipple type="button" label="Cancel"
                              class="p-button-sm p-button-rounded p-button-secondary btn-clear" (click)="cancel()"></button>                    
              </div>
            </div>
        </div>
    </div>
</p-dialog>
