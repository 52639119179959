import { Component } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { PrimeNGConfig } from 'primeng/api';
import { LoginService } from "./login/login.service";
// import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading$ = this.loginService.loading$;
  isConnected = true;
  noInternetConnection: boolean;
  deviceInfo = null;
  constructor(private primengConfig: PrimeNGConfig,
    private connectionService: ConnectionService,
    private loginService: LoginService,
  ) {
    // private deviceService: DeviceDetectorService
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.noInternetConnection = false;
      }
      else {
        this.noInternetConnection = true;
      }
    })
  }
  title = 'Optisol Erp';
  ngOnInit() {
    this.primengConfig.ripple = true;
    // this.epicFunction();
  }
  // epicFunction() {
  //   this.deviceInfo = this.deviceService.getDeviceInfo();
  //   const isMobile = this.deviceService.isMobile();
  //   const isTablet = this.deviceService.isTablet();
  //   const isDesktopDevice = this.deviceService.isDesktop();
  // }
}
