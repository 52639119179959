import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, BehaviorSubject } from "rxjs";
import { first, catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: 'root'
})
export class RaiseticketService {
  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ 
    'Content-Type': 'application/json; charset=utf-8',
    })
    
  };
  constructor(  private http: HttpClient) { }
  get_category(result){
    return this.http.post(`${environment.url}/api/get_subcategory`,result,this.httpOptions);
  }
  create_ticket(result){
    return this.http.post(`${environment.url}/api/create_ticket`,result,this.httpOptions);
  }
  list_ticket(result){
    return this.http.post(`${environment.url}/api/list_ticket`,result,this.httpOptions);
  }
  ticket_delete(result){
    return this.http.post(`${environment.url}/api/ticket_delete`,result,this.httpOptions);
  }
  send_msg(result){
    return this.http.post(`${environment.url}/api/send_message`,result,this.httpOptions);
  }
  ticket_approval(result){
    return this.http.post(`${environment.url}/api/ticket_approval`,result,this.httpOptions);
  }
  ticket_approval_action(result){
    return this.http.post(`${environment.url}/api/ticket_approval_action`,result,this.httpOptions);
  }
  ticket_action(result){
    return this.http.post(`${environment.url}/api/ticket_action`,result,this.httpOptions);
  }
  update_ticket_assigners(result){
    return this.http.post(`${environment.url}/api/update_ticket_assigners`,result,this.httpOptions);
  }
  generate_ticket_reports(result){
    return this.http.post(`${environment.url}/api/generate_ticket_reports`,result,this.httpOptions);
  }
  dashboard_count(result){
    return this.http.post(`${environment.url}/api/department_ticket/count`,result,this.httpOptions);
  }
  dashboard_list_info(result){
    return this.http.post(`${environment.url}/api/dashboard/filter`,result,this.httpOptions);
  }
  
  // download_report(result){
  
  //   return this.http.get(`${environment.url}/api/download_ticket_report`,{
  //   params:{
  //     "assigned_to": "",
  //     "category": result.catelist,
  //     "department":result.deptlist,
  //     "download": "true",
  //     "limit": 10,
  //     "page": 1,
  //     "periodFrom":result.from_date,
  //     "periodTo": result.to_date,
  //     "priority":result.prioritylist ,
  //     "report_type": result.reportlist,
  //     "subcategory": result.subcategorylist,
  //     "ticket_status": result.statuslist
  //   }
  //   });
  // }
 
  // download_report(catelist,deptlist,from_date,to_date,prioritylist,reportlist,subcategorylist,statuslist){
  //     const Params = new HttpParams({
  //     fromObject:{
  //       assigned_to: "",
  //       category:catelist,
  //       department:deptlist,
  //       download: true,
  //       limit: 10,
  //       page: 1,
  //       periodFrom:from_date,
  //       periodTo:to_date,
  //       priority: prioritylist,
  //       report_type:reportlist,
  //       subcategory:subcategorylist,
  //       ticket_status:statuslist,
  //     }
  //   });
   //   return this.http.get(`${environment.url}/api/download_ticket_report`,{params:Params});
  // }
}
