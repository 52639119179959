
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContentComponent } from "./layout/content/content.component";
import { AuthGuard } from "./services/auth.guard";
const routes: Routes = [

  // { path: "content", component: ContentComponent },
  
  {
    path:"pipeline",
    loadChildren:() =>import('./pipeline/pipeline.module').then(m =>m.PipelineModule)
  },
  { path: "", redirectTo: "login/login", pathMatch: "full" },
  {
    path: "login",
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule), data: { header: false }
  },
  {
    path: "not_found",
    loadChildren: () => import('./not_found/notfound.module').then(m => m.NotfoundModule)
  },


  {
    path: "", component: ContentComponent, canActivate: [AuthGuard],
    children: [
      {
        path: "profile",
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: "leave",
        loadChildren: () => import('./leave/leave.module').then(m => m.LeaveModule)
      },
      {
        path: "wfh",
        loadChildren: () => import('./wfh/wfh.module').then(m => m.WfhModule)
      },
      {
        path: "permission",
        loadChildren: () => import('./permission/permission.module').then(m => m.PermissionModule)
      },
      {
        path: "onduty",
        loadChildren: () => import('./onduty/onduty.module').then(m => m.OndutyModule)
      },
      {
        path: "holidaylist",
        loadChildren: () => import('./holidaylist/holidaylist.module').then(m => m.HolidaylistModule)
      },
      {
        path: "attendance",
        loadChildren: () => import('./attendance/attendance.module').then(m => m.AttendanceModule)
      },
      {
        path: "compoff",
        loadChildren: () => import('./compoff/compoff.module').then(m => m.CompoffModule)
      },
      {
        path: "documents",
        loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule)
      },
      {
        path: "support",
        loadChildren: () => import('./supportcontact/supportcontact.module').then(m => m.SupportcontactModule)
      },
      {
        path: "team",
        loadChildren: () => import('./team/team.module').then(m => m.TeamModule)
      },
      {
        path: "letter",
        loadChildren: () => import('./letter_approval/letterapproval.module').then(m => m.letterapprovalModule)
      },
       {
        path:"conference",
        loadChildren: () => import('./conference-room/conference-room.module').then(m =>m.ConferenceroomModule)
      },
      {
        path: "resignation",
        loadChildren: () => import('./resignation/resignation.module').then(m => m.ResignationModule)
      },
      {
        path: "recruitment",
        loadChildren: () => import('./recruitment/recruitment.module').then(m => m.RecruitmentModule)
      },
      {
        path: "ticket",
        loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule)
      },
      {
        path: "tds",
        loadChildren: () => import('./tds/tds.module').then(m => m.TdsModule)
      },
      {
        path: "employeedata",
        loadChildren: () => import('./employeedata/employeedata.module').then(m => m.EmployeeModule)
      },
      {
        path: "employeedetails",
        loadChildren: () => import('./employeedetails/employeedetails.module').then(m => m.EmployeedetailsModule)
      },
      // { path: "unauthorized", component: UnauthorizedComponent },
    ]
  },
 
  { path: "**", redirectTo: "not_found" },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
