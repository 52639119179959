<div class="container-scroller">
    <app-navbar></app-navbar>
    <div class="container-fluid page-body-wrapper">   
    <app-sidebar></app-sidebar>        
      <div class="main-panel">
        <div class="page-breadcrumb">
          <div class="row align-items-center m-0">
              <div class="col-lg-6 col-md-4 col-sm-4 col-xs-12 p-0" [hidden]="check()">
                  <h4 class="page-title" >{{heading}}</h4>
              </div >        
          </div>
        </div>
        <div class="content-wrapper">
          <router-outlet ></router-outlet>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>