import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, BehaviorSubject } from "rxjs";
import { first, catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";



@Injectable({
  providedIn: "root",
})

export class WfhService {

  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    })

  };
  private messageSource = new BehaviorSubject('');
  currentcount = this.messageSource.asObservable();

  constructor(
    private http: HttpClient,

    private router: Router
  ) { }


  wfh_Count(count: any) {
    this.messageSource.next(count)
  }



  get_rights(result2) {
    return this.http.post(`${environment.url}/api/get_bulk_apply_rights/`, result2, this.httpOptions);
  }
  wfh_approve(result2) {
    return this.http.post(`${environment.url}/api/approve_wfh_list`, result2, this.httpOptions);
  }
  wfh_apply(wfh) {
    return this.http.post(`${environment.url}/api/wfh_apply`, wfh, this.httpOptions);
  }
  wfh_lists(wfh_list) {
    return this.http.post(`${environment.url}/api/wfh_lists`, wfh_list, this.httpOptions);
  }
  wfh_delete(wfh_del) {
    return this.http.post(`${environment.url}/api/delete_list`, wfh_del, this.httpOptions);
  }
  wfh_action(wfh) {
    return this.http.post(`${environment.url}/api/wfh_action`, wfh, this.httpOptions);
  }
  bulk_approve(result) {
    return this.http.post(`${environment.url}/api/bulk_wfh_action`, result, this.httpOptions);
  }
}
