


<div >
    <h3 class="capitalize">{{ date | titlecase }} {{ department | titlecase }} {{ status | titlecase}} {{" "}}Ticket Details</h3>
</div>
<div class="button-link  mb-4 mt-3">
    <button pButton pRipple type="button" class="p-button-rounded p-button-outlined"  label="Back"
        (click)="router.navigate(['ticket/dashboard'])">
    </button>
</div>




<section class="profilepage_css tabcontent">
    <!-- <ngb-tabset class="profile_tabs" > -->
        <!-- <ngb-tab > -->
            
            <!-- <ng-template ngbTabContent> -->
                <div *ngIf="display!=true" class="bg_shadow">
                    <p-table responsiveLayout="scroll" #dt1 [value]="ticket_list" dataKey="id" [rows]="10"
                        styleClass="p-datatable-customers p-datatable-gridlines" 
                        [globalFilterFields]="['raised_date','ticket_id','subject','description','category','subcategory','priority','assigned_to']"
                        styleClass="prime-table-override">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Raised Date</th>
                                <th>Ticket Id</th>
                                <th>Subject</th>
                                <th>Description</th>
                                <th>Category</th>
                                <th>Sub Category</th>
                                <th>Priority</th>
                                <th>Assigned To</th>
                                <!-- <th style="text-align: center;" style="text-align: center;width:100px;">Action</th> -->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-ticket_list>
                            <tr>
                                <td><span>{{ticket_list.raised_date}}</span>
                                    <br>
                                    <span *ngIf="i==='Closed'" style="color: rgba(245,130,31,1);">Closed
                                        Date-{{ticket_list.ticket_closed_date}}</span>
                                </td>
                                <td>{{ticket_list.ticket_id}}</td>
                                <td>{{ticket_list.subject}}</td>
                                <td>{{ticket_list.description}}</td>
                                <td>{{ticket_list.category}}</td>
                                <td>{{ticket_list.subcategory}}</td>
                                <td>{{ticket_list.priority}}</td>
                                <td>{{ticket_list.assigned_to}}</td>
                                <!-- <td class="action_btn" style="text-align: center;width:100px;">

                                    <button *ngIf="i==='New' || i==='First Approval' || i==='Second Approval'||
                                        i==='In Progress'||(( i==='Closed') && ticket_list.limit>0 )" pButton pRipple
                                        icon="pi pi-comment" class="p-button-rounded p-button-primary"
                                        (click)="send_msg(ticket_list.id)"></button>
                                    <button *ngIf="i==='New'" pButton pRipple icon="pi pi-trash"
                                        class="p-button-rounded p-button-danger"
                                        (click)="deletelist(ticket_list.id)"></button>
                                    <button
                                        *ngIf="(i==='Second Approval' || i==='Closed' || i==='In Progress') && ticket_list.approval_logs.length>0"
                                        pButton pRipple icon="pi pi-info" class="p-button-rounded p-button-primary"
                                        (click)="details_of_approval(ticket_list.id)"></button> -->

                            </tr>

                        </ng-template>

                    </p-table>

                </div>
                <div *ngIf="display===true " class="empty-state bg_shadow">
                    <div class="empty-state__content">
                        <div class="empty-state__icon">
                            <img src="assets/images/no-results.jpeg" alt="">
                        </div>
                        <div class="empty-state__message">No Records Found</div>

                    </div>

                </div>

            <!-- </ng-template> -->
        <!-- </ngb-tab> -->

     <!-- </ngb-tabset> -->
     <div *ngIf="display===false && total_records>10">
        <p-paginator [rows]="10" [totalRecords]="total_records"  (onPageChange)="paginate($event)"></p-paginator>
        </div>
</section>