import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, BehaviorSubject } from "rxjs";
import { first, catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ConferenceRoomService {
  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ 
    'Content-Type': 'application/json; charset=utf-8',
    })
    
  };
  constructor(
    private http: HttpClient,
    private router: Router) { }


    conference_rooms(result2){
      return this.http.post(`${environment.url}/api/conference_rooms`,result2,this.httpOptions);
    }
    getlocations(result2){
      return this.http.post(`${environment.url}/api/locations`,result2,this.httpOptions);
    }
    conference_room_booking(result2){
      return this.http.post(`${environment.url}/api/conference_rooms/booking_details`,result2,this.httpOptions);
    }
    booked_info(result2){
      return this.http.post(`${environment.url}/api/conference_rooms/booked_info`,result2,this.httpOptions);
    }
    booking_cancel(result2){
      return this.http.post(`${environment.url}/api/conference_rooms/cancel_booking`,result2,this.httpOptions);
    }
}
