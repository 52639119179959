import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, BehaviorSubject } from "rxjs";
import { first, catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";



@Injectable({
  providedIn: "root",
})

export class ResignationService {
  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ 
    'Content-Type': 'application/json; charset=utf-8',
    })
    
  };

  private messageSource = new BehaviorSubject('');
  currentcount = this.messageSource.asObservable();
constructor(
    private http: HttpClient,
    private router: Router
  ) {}


  Resignation_Count(count:any){
    this.messageSource.next(count);
  }

 get_notice_period(result2){
   return this.http.post(`${environment.url}/api/get_notice_period`,result2,this.httpOptions);
 }
 approve_resignation(result2){
  return this.http.post(`${environment.url}/api/resignation_list_approval`,result2,this.httpOptions);
}
resignation_apply(result2){
  return this.http.post(`${environment.url}/api/resignation_apply`,result2,this.httpOptions);
}
resignation_list_view(result2){
  return this.http.post(`${environment.url}/api/resignation_list`,result2,this.httpOptions);
}
resignation_delete(result2){
  return this.http.post(`${environment.url}/api/resignation_apply_delete`,result2,this.httpOptions);
}
resignation_cancel(result2){
  return this.http.post(`${environment.url}/api/resignation_cancellation`,result2,this.httpOptions);
}
resign_action(resign){
  return this.http.post(`${environment.url}/api/resignation_action`,resign,this.httpOptions);
}
resignation_approval_status(resign){
  return this.http.post(`${environment.url}/api/resignation_get_approval_status`,resign,this.httpOptions);
}
save_updation(resign){
  return this.http.post(`${environment.url}/api/save_new_notice_period`,resign,this.httpOptions);
}
exit_interview_form(resign){
  return this.http.post(`${environment.url}/api/exit_interview_form`,resign,this.httpOptions);
}
check_approval_status(resign){
  return this.http.post(`${environment.url}api/resignation_get_approval_status`,resign,this.httpOptions);
}
}
