import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, BehaviorSubject } from "rxjs";
import { first, catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: "root",
})

export class CompoffService {
  
 



  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ 
    'Content-Type': 'application/json; charset=utf-8',
    })
    
  };

  private messageSource = new BehaviorSubject('');
  currentcount = this.messageSource.asObservable();
constructor(
    private http: HttpClient,
 
    private router: Router
  ) {}

  Compoff_Count(count: any) {
  this.messageSource.next(count)
  }
  
  get_rights(result2){
    return this.http.post(`${environment.url}/api/get_bulk_apply_rights/`,result2,this.httpOptions);
  }
  approve_compoff(result2){
    return this.http.post(`${environment.url}/api/approve_compoff_lists`,result2,this.httpOptions);
  }
  compoff_list(result2){
    return this.http.post(`${environment.url}/api/compoff_lists`,result2,this.httpOptions);
  }
  comp_off_apply(comp_off){
    return this.http.post(`${environment.url}/api/compoff_apply`,comp_off,this.httpOptions);
  }
  compoff_mapping(result2){
    return this.http.post(`${environment.url}/api/compoff_mapping`,result2,this.httpOptions);
  }
  compoff_action(compoff){
    return this.http.post(`${environment.url}/api/compoff_action`,compoff,this.httpOptions);
  }
  compoff_map_calculate(result){
    return this.http.post(`${environment.url}/api/compoff_map_calculate`,result,this.httpOptions);
  }
  compoff_del(result){
    return this.http.post(`${environment.url}/api/delete_list`,result,this.httpOptions);
  }
}
