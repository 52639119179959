import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, BehaviorSubject } from "rxjs";
import { first, catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";



@Injectable({
  providedIn: "root",
})

export class PermissionService {

  httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({ 
    'Content-Type': 'application/json; charset=utf-8',
    })
    
  };
  private messageSource = new BehaviorSubject('');
  currentcount = this.messageSource.asObservable();
constructor(
    private http: HttpClient,
 
    private router: Router
  ) {}



  Permission_Count(count: any) {
  this.messageSource.next(count)
  }



  get_rights(result2){
    return this.http.post(`${environment.url}/api/get_bulk_apply_rights/`,result2,this.httpOptions);
  }
  perm_approve(result2){
    return this.http.post(`${environment.url}/api/approve_perm_lists`,result2,this.httpOptions);
  }
  perm_apply(permission){
    return this.http.post(`${environment.url}/api/perm_apply`,permission,this.httpOptions);
  }
  perm_list(result2){
    return this.http.post(`${environment.url}/api/perm_lists`,result2,this.httpOptions);
  }
  perm_delete(perm_del){
    return this.http.post(`${environment.url}/api/delete_list`,perm_del,this.httpOptions);
  }
  perm_action(perm){
    return this.http.post(`${environment.url}/api/perm_action`,perm,this.httpOptions);
   }
}