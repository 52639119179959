import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from "@angular/router";
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor{

  constructor(private router :Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
  req = req.clone({
    withCredentials: true,
  });
  // return next.handle(req);
  return next.handle(req).pipe(
    tap(
      (res:any) => {
        console.log("res",res)
        if(res){
        const jsonData = res;
        console.log("jsonData",jsonData)
        if ( (jsonData && jsonData.body&& jsonData.body.error &&  jsonData.body.error.code === 100)) {
          this.router.navigate(['/login']);
        }
      }
      },
      (err: any) => {
        console.log("err",err)
        if (err) {
          const serverErrorCode = [500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511];
          if (err && err.error &&err.error.code === 100) {
            this.router.navigate(['/login']);
          }
        }
      }
    )
  );
  }

}

