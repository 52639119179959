import { Component, OnInit } from '@angular/core';
import { RaiseticketService } from '../raiseticket/raiseticket.service';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ticket-dashboard-info',
  templateUrl: './ticket-dashboard-info.component.html',
  styleUrls: ['./ticket-dashboard-info.component.scss']
})
export class TicketDashboardInfoComponent implements OnInit {
  ticket_list;
  total_records;
  total_ticket_list
  display: boolean = false;
  department: string | null = null;
  status: string | null = null;
  date: string | null = null;
  constructor(private raiseticket: RaiseticketService,public router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.department = params['department'] || null;
      this.status = params['status'] || null;
      this.date = params['date'] || null;
    });
    var result = {
      "params": {
        search_filter: "",
        status: this.status,
        type: "",
        department:this.department,
        date: this.date
      }
    }
    this.raiseticket.dashboard_list_info(result).subscribe(ticket => {
      this.ticket_list = ticket['result']['data']['data'];
      this.total_records = ticket['result']['data'].total;
      console.log(this.total_records)
      console.log(this.ticket_list)
      if (ticket['result']['data'].message === 'No Records Found') {
        this.display = true;
      } else {
        this.display = false;
      }
    });
  }
  paginate(e) {
    var dummy = {
      "params": {
        "limit": 10,
        "page": (e.page) + 1
      }
    }
 
    this.raiseticket.dashboard_list_info(dummy).subscribe(ticket => {
      this.ticket_list = ticket['result']['data']['data'];
      console.log(this.ticket_list)
      if (ticket['result']['data'].message === 'No Records Found') {
        this.display = true;
      } else {
        this.display = false;
      }
    });
  }
}
